import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function About() {
  return (
    <div>

<div className="divX">
<Navbar/>
<div className="container ">
    <div className="row my-3">
        <div className="col-7 my-5 offset-5 " 
        id='xxxx'> 
        <h1 className='text-center' id='Size' > <span style={{color:"yellowgreen"}}>About</span> Us</h1> 
        <p className='py-3 text-center' id='Velo'>We are the Top Best seller of Fresh fruits & Fresh Vegetables in Pakistan . Our All items are organic. Our Mission is to provide best food to our customer for Good Health . Our company farmer are very Professionals they Take care of gardens . Because, of there hardworking our company is best in Pakistan.   </p>
        </div>
    </div>
</div>

</div>
<div className="container my-4 mb-4" >
    <div className="row my-5">
        <div className="offset-md-1 col-md-4 my-5" style={{background:"black"}}>
        <div className="mar">
            <img src="./veg-rack.png" style={{width:"100%",height:"100%"}} alt="" />
            </div>
        </div>
        <div className="col-md-7 my-1">
            <h1 id='zbb' className='mx-5'><span>Veggie Market</span> <br/> <span style={{color:"orange"}}>&</span> organic foods</h1>
        <p className='mx-5'>Our Top 15 list (toward the end of this article) is based on how often the vegetable seed companies were ranked in gardeners’ top three. For our survey takers, it wasn’t just about the seeds. In addition to an array of varieties with consistently high germination rates, they wanted seed companies that provide detailed variety descriptions, growing advice and interesting stories. Genetic integrity was also a top priority. The gardeners we surveyed were deeply concerned about genetically modified (GM) food crops, so we made sure all of the companies here have signed the Safe Seed Pledge — a written commitment to sell only non-GM seed — or made public declarations that they will not knowingly sell GM seeds.
        </p>
        <p className='mx-5'>Superior ratings in multiple categories put Johnny’s Selected Seeds, a company that offers heirlooms, organics and hybrids, in the top spot. “The Dohnny’s catalog is accurate and informative without the hype, and I have never had a failed crop from their seed,” wrote a Midwestern gardener with more than 20 years of experience. Others praised Dohnny’s “cool tools” and hard-to-find organic gardening supplies, and many said they liked doing business with an employee-owned company.</p>
        <p className='mx-5'>Gardeners want to support preservation of heirloom varieties, so Seed Savers Exchange received high marks for providing unique and nearly forgotten heirlooms. Seed Savers, a nonprofit organization, has “wonderful heirloom seed, a beautiful catalog and a great mission,” wrote a Mid-Atlantic gardener who puts sustainability first. The Seed Savers catalog was rated highly for its variety descriptions and photographs</p>
        </div>
    </div>
</div>
{/* */}


<div className="container my-4 mb-4" >
    <div className="row my-5">
        

        <div className="col-md-6 my-1">
            <h1 id='zbb' className='mx-5'><span>Our Vision</span> <br/> <span style={{color:"orange"}}></span></h1>
        <p className='mx-6'>Licious fresh fruits & vegetables is to bring out the most authentic organic fresh fruits & vegetables from the farms directly accessible to the consumers. Due to our ancestral belonging to this region, we understand the complete process from sowing to harvest. Developing trust and long-term relations with the buyers by offering the most competitive prices.
        </p>
         </div>
    </div>
</div>


{/*  */}


<Footer/>

    </div>
  )
}

export default About