import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Amm() {
  return (
    <div>
<div>    


        <Navbar/>
        <div className="container" style={{backgroundColor:"whitesmoke"}} >
   
    <div className="row my-5">
      
        <div className="col-md-6">
            <h2 className='about-text1 text-center' style={{color:""}}>Sindri <span style={{color:"black"}}>Mango</span> </h2>
   
            <p className='my-5 text-center' > <span style={{color:"red",fontWeight:"bolder"}}>"Sindri Mango"</span> (sometimes spelt Anwar Rataul) is a small, yellow variety of mango known for its sweetness and fiberlessness. ... It is very sweet in taste with less fiber..</p>
        </div>

        <div className="col-md-4 offset-md-2 my-4">
        <div className="vege1" style={{height:"400px"}}>
        <img src="./sindrimango.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
        </div>
    </div>

</div>


<div className="container" style={{backgroundColor:"white"}} >
    <div className="row my-5">
      
        <div className="col-md-6">
            <h2 className='about-text1 text-center' style={{color:""}}>Chuansa <span style={{color:"black"}}>Mango</span> </h2>
   
            <p className='my-5 text-center' >We deliver fresh farm <span style={{color:"red", fontWeight:"bolder"}}>"Chunsa"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
        </div>

        <div className="col-md-4 offset-md-2 my-4">
        <div className="vege1" style={{height:"400px"}}>
        <img src="./chunsa.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
        </div>
    </div>
</div>



<div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
      
        <div className="col-md-6">
            <h2 className='about-text1 text-center' style={{color:""}}>Sunehri <span style={{color:"black"}}>Mango</span> </h2>

            <p className='my-5 text-center' >We deliver fresh farm <span style={{color:"red", fontWeight:"bolder"}}>"Sunehri"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
        </div>

        <div className="col-md-4 offset-md-2 my-4">
        <div className="vege1" style={{height:"400px"}}>
        <img src="./SunehriMango.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
        </div>
    </div>
</div>


{/* Add New MangO */}

<div className="container" style={{backgroundColor:"whitesmoke"}} >
   
   <div className="row my-5">
     
       <div className="col-md-6">
           <h2 className='about-text1 text-center' style={{color:""}}>Anwar <span style={{color:"black"}}>Ratol</span> </h2>
  
           <p className='my-5 text-center' > <span style={{color:"red",fontWeight:"bolder"}}>"Anwar Ratol"</span> (sometimes spelt Anwar Rataul) is a small, yellow variety of mango known for its sweetness and fiberlessness. ... It is very sweet in taste with less fiber..</p>
       </div>

       <div className="col-md-4 offset-md-2 my-4">
       <div className="vege1" style={{height:"400px"}}>
       <img src="./AnwarRatolMango.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
       </div>
   </div>

</div>


{/* Add new MangO */}
        </div>   
        <Footer/>
    </div>
  )
}

export default Amm