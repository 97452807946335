import './App.css';
// import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './component2/About';

import Amm from './component3/Amm';
import Orange from './component3/Orange';
// import Persimons from './component3/Persimons';
import Potato from './component3/Potato';
import Onions from './component3/Onions';
import Contact from './component3/Contact';


import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
function App() {
return (

  <>
 <Router>
<Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>

          <Route path="/amm">
            <Amm />
          
          </Route>
          <Route path="/orange">
            <Orange />
          </Route>
          
          <Route path="/onions">
            <Onions />
          </Route>
          <Route path="/potato">
            <Potato />
          </Route>
        <Route path="/contact">
<Contact/>
        </Route>
        </Switch>
</Router>  

</>


);
}

export default App;




