import React from 'react'
import { Link } from 'react-router-dom';



export default function Carousel() {
  return (
    <>

<div id="demo" class="carousel slide" data-bs-ride="carousel" >
<div className="container mb-5">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <h1 className='mx-auto mb-5 text-center' style={{width:"300px",height:"60px",background:"black",color:"yellow",fontFamily:"Roboto",border:"5px solid yellow",fontWeight:"1000",boxShadow:"0px 0px 30px 15px black",padding:"3px",boxSizing:"border-box",borderRadius:"30px"}} id='mango'>Mangoes Type</h1>
  <Link to="/amm" style={{textDecoration:"none",listStyle:"none"}}>
      <div className="row">
          <div className="col-4">
              <div className="slide" ><img src="./chunsa.jpg" style={{width:"100%",height:"100%"}} alt="" />
              
              </div>
              <h3 className='text-center'>Chuansa</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./AnwarRatolMango.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Anwar Ratol</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./sindrimango.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Sindri</h3>
          </div>
      </div>


    </Link>
    </div>


    {/* <div class="carousel-item">
    <h1 className='mx-auto mb-5 text-center' style={{width:"380px",background:"black",color:"red"}} id='mango'>Persimmons Type</h1>
    <div className="row">
          <div className="col-4">
              <div className="slide" ><img src="./persimonv.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
              <h3 className='text-center'>Fuyu</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./hachiya.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Hachiya</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./ripe.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Ripe</h3>
          </div>
      </div>
    </div> */}


    <div class="carousel-item">
    <h1 className='text-center mb-5 mx-auto' style={{width:"300px",height:"60px",background:"black",color:"orange",fontFamily:"Roboto",border:"5px solid orange",fontWeight:"1000",boxShadow:"0px 0px 30px 15px black",padding:"3px",boxSizing:"border-box",borderRadius:"30px"}}  id='mango'>Orange Type</h1>
    <Link to="/orange" style={{textDecoration:"none",listStyle:"none"}}>
      <div className="row">
          <div className="col-4">
              <div className="slide" ><img src="./Valencia.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
              <h3 className='text-center'>Valencia Orange</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./MusambiOrange.webp" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Mosambi</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./MandarinOrange.jpeg" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Mandrain</h3>
          </div>
      </div>

      </Link>
    </div>

    <div class="carousel-item">
    <h1 className='text-center mb-5 mx-auto' style={{width:"300px",height:"60px",background:"black",color:"goldenrod",fontFamily:"Roboto",border:"5px solid goldenrod",fontWeight:"1000",boxShadow:"0px 0px 30px 15px black",padding:"3px",boxSizing:"border-box",borderRadius:"30px"}} id='mango'>Potato Type</h1>
    <Link to="/potato" style={{textDecoration:"none",listStyle:"none"}}>
    <div className="row">
          <div className="col-4">
              <div className="slide" ><img src="./AsterixPotato.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
              <h3 className='text-center'>Asterix Potato</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./Lady-RosettaPotato.png" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>LadyRosetta</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./mozikaPotato.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Mozika Potato</h3>
          </div>
      </div>
      </Link>
    </div>


    <div class="carousel-item" style={{textDecoration:"none",listStyle:"none"}}>
    <h1 className='text-center mb-5 mx-auto' style={{width:"300px",height:"60px",background:"black",color:"brown",fontFamily:"Roboto",border:"5px solid brown",fontWeight:"1000",boxShadow:"0px 0px 30px 15px black",padding:"3px",boxSizing:"border-box",borderRadius:"30px"}} id='mango'>Onions Type</h1>
    <Link to="/onions" style={{textDecoration:"none",listStyle:"none"}}>
    <div className="row" >
          <div className="col-4">
              <div className="slide" ><img src="./RedOnion.jpg" style={{width:"100%",height:"100%"}} alt="" /></div>
              <h3 className='text-center' style={{textDecoration:"none",listStyle:"none"}}>Red Onion</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./WhiteOnion.webp" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>White Onion</h3>
          </div>
          <div className="col-4">
          <div className="slide" ><img src="./YellowOnion.webp" style={{width:"100%",height:"100%"}} alt="" /></div>
          <h3 className='text-center'>Brown Onions</h3>
          </div>
      </div>
      </Link>
    </div>
  </div>
</div>
  
  <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span class="carousel-control-next-icon"></span>
  </button>
</div> 
    </>
    )
  }
  
 