import React from 'react';
import { Link } from 'react-router-dom';
export default function Navbar () {
  return (
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id='nav1'>
  <div className="container-fluid">
    
    <a className="navbar-brand" href="/"><img src="./logo2.png" className='logo' alt="" /></a>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>

    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <Link to="/" style={{textDecoration:"none",listStyle:"none"}}>

        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="/">Home</a>
        </li>
        </Link>

{/*  */}



{/*  */}
<Link to="/about" style={{textDecoration:"none",listStyle:"none"}}> 
        <li className="nav-item">
          <a className="nav-link active" href="/about">About</a>
        </li>
        </Link>

        <li className="nav-item dropdown">
          <a className="nav-link active dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Product </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown" >

            <li className="">

            <a className="dropdown-item  dropdown-toggle A" href="/"id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Fresh Fruits</a>
            
            <Link className="dropdown-item c " to="/amm" > <span className='B'></span> Mango</Link>
            <Link className="dropdown-item c" to="/orange" > <span className='B'></span> Orange</Link>
            {/* <Link className="dropdown-item c " to="/persimmons" > <span className='B'></span> Persimmon</Link> */}

            </li>


            <li>
            <a className="dropdown-item dropdown-toggle A" href="/">Fresh Vegetables</a>
            <Link className="dropdown-item c " to="/potato" > <span className='B'> </span>  Potatoes</Link>
            <Link className="dropdown-item c" to="/onions" > <span className='B'> </span> Onion's</Link>
            </li>
           
          </ul>
        </li>
        <li className="nav-item ">
          <Link className="nav-link active" to="/contact">Contact</Link>
        </li>
      </ul>
     
    </div>
  </div>

</nav>
  )
}


