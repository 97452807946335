import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Contact() {
  return (
    <div>
    <Navbar/>
<form>
    <div className="container-fluid text-center" style={{background: "rgb(233, 174, 64)"}}>
    <h1 >CONTACT US </h1>
    <p id="pa">Our team of customer care ninjas is ready to hear from you </p>
    </div>
   

    <div className="container ">
    <div className="row">
    <div className="col">
    <h3>Reach out to us!</h3>
    <p id="par" >Get the question about lander? Are you intersted in partnering with<br/>us ? Have some suggestions or just want to say Hi ? Contact us </p>
    </div>
    <div className="col">
    <h3>Customer Care </h3>
    <p id="par" >Not sure where to start ? Need help adding that extra mojo to your <br/> landing page? Just visit Our <span>Help Center</span> or get in touch with us.  </p>
    </div>
    </div>
    </div> 


    <div className="container" style={{height:"600px",border:"3px solid rgb(233, 174, 64)"}}>
    <h2 className="py-3 text-center"></h2>
    <div className="row py-3">
    <div className="col-md-6">
    <div className="form-group">
    <label >Name</label>
    <input type="text" class="form-control form-control"/> 
    </div>


    <div className="form-group">
    <label >Subject</label>
    <select name="" id="" class="form-control">
    <option value="">Mango</option>
    <option value="">Orange</option> 
    <option value="">Onion</option>
    <option value="">Potato</option>

    </select>
    </div>
   
    <div className="form-group">
    <label >Your Email Adress</label>
    <input type="text" placeholder='Abc@gmail.com' value="required" class="form-control form-control"  /> 
    </div>


    <div className="form-group">
    <label >Contact Number </label>
    <input type="number" class="form-control"  />
    </div>
     
    
    <div className="form-group">
    <label >Whatsapp</label>
    <textarea name="" class="form-control"></textarea>
    </div> 

<a href="#" ><button class="Btn-10" >Submit</button></a>

   
</div> 
</div>



</div>

    </form>












<Footer/>
    </div>
  )
}

export default Contact
