import React from 'react';
import Navbar from './Navbar';
import Carousel from './Carousel';
import Footer from './Footer';
import { Link } from 'react-router-dom';

export default function Home() {
  return( 
      <>
     
     <Navbar/>
 <div className="back">
     <div className="container">
         <div className="row ">
             <div className="col-8  ">
                 {/* <h1 className='text-light text-center' id='word1'> Fresh Vegetable and Fruit Importer & Exporter</h1> */}
                 {/* <p className='text-light text-center mt-5 lead'>We are the Best Seller in Pakistan.We sell Fresh Fruits and Vegetables with Best Quality</p> */}
             
             </div>
         </div>
        
     </div>
 </div>

<div className="container" >
    <div className="row">
        <div className="col-md-4 my-4">
        <div className="vege1" style={{width:"",height:"400px"}}>

<img src="./vege1.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /> </div>
       
        </div>
        <div className="col-md-6 offset-md-2">
            <h2 className='about-text1' style={{color:"black"}}>About <span style={{color:"black"}}>Vegetables & Fruits</span> </h2>
            <h1 className='about-text2' style={{ color:"black"}}>Super <span style={{color:"black"}}>Market</span></h1>
            <p className='my-5'>We deliver fresh farm  vegetables to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
        </div>
    </div>
</div>



{/* ---------------------------P1------------- */}


<div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
      
        <div className="col-md-6">
            <h2 className='about-text1' style={{color:"yellowgreen"}}> <span style={{color:"black"}}>Fruits & Vegetables</span> </h2>
            <h1 className='about-text2' style={{color:"black"}}> <span style={{color:"black"}}>Market</span> </h1>
            <p className='my-5' >We deliver fresh farm  Fruits to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
        </div>

        <div className="col-md-4 offset-md-2 my-4">
        <div className="vege1" style={{height:"400px"}}>
        <img src="./Shop.webp" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
        </div>
    </div>
</div>


<div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row">
        <div className="col-md-4 my-4"  >
        <div className="vege1" style={{width:"400px",height:"400px"}}>
        <img src="./develery-boy2.png" style={{width:"100%", height:"100%",}} alt="" /></div>
        </div>
        <div className="col-md-6 offset-md-2">
            <h2 className='about-text1' style={{color:"red"}}>Why Choose Us? <span style={{color:"green"}}></span> </h2>
            <h1 className='about-text2' style={{ color:"yellowgreen"}}>More Than 15 Years In Business
 <span style={{color:"yellow"}}></span></h1>
            <p className='my-5'>We have continually strived to meet or exceed the customer’s expectation for product quality, service delivery and cost. We established quality objectives to accomplish this, in a profitable, ethical and professional manner. We committed to continual improvement of its quality management system through employee awareness, preventive action, and quality management and feedback.</p>
        </div>
    </div>
</div>







<div className="container" style={{backgroundColor:"white"}} >
    <div className="row my-5">
      
        <div className="col-md-6">
            <h2 className='about-text1 text-center' style={{color:"yellowgreen"}}><span style={{color:"black"}}>HoneyMango</span> </h2>
   
            <p className='my-5 text-center' >We deliver fresh farm <span style={{color:"yellow", fontWeight:"bolder"}}>"Mango"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
        </div>

        <div className="col-md-4 offset-md-2 my-4">
        <div className="vege1" style={{height:"400px"}}>
        <Link to="/amm">  <img src="./Fresh.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></Link></div>
        </div>
    </div>
</div>


<div className="container" style={{backgroundColor:"whitesmoke"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:"black"}}>JuicyOrange <span style={{color:"black"}}></span> </h2>
    
    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"orange", fontWeight:"bolder"}}>"Orange"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<Link to="/orange"><img src="./JuicyOrange.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></Link></div>
</div>
</div>
</div>


<div className="container" style={{backgroundColor:"white"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}> Fresh Jamun <span style={{color:"black"}}></span> </h2>

    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"purple", fontWeight:"bolder"}}>"Jamun"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./juman.png" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>


<div className="container" style={{backgroundColor:"white"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>Fresh Onions <span style={{color:"red"}}></span> </h2>

    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"red", fontWeight:"bolder"}}>"Onions"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<Link to="/onions"><img src="./FreshOnion.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></Link></div>
</div>
</div>
</div>


<div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:""}}>Fresh Potato<span style={{color:"black"}}></span> </h2>
    
        <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"goldenrod", fontWeight:"bolder"}}>"Potato"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <Link to="/potato"><img src="./FreshPotato.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></Link></div>
    </div>
    </div>
    </div>


    <div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:""}}>Fresh Peas<span style={{color:"green"}}></span> </h2>
    
        <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"green", fontWeight:"bolder"}}>"Peas"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <img src="./mater.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
    </div>
    </div>
    </div>


    <div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:"", fontWeight:"bolder",fontFamily:"Roboto"}}>Bitter Gourd<span style={{color:"green", }}></span> </h2>
    
        <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"green", fontWeight:"bolder"}}>"Bitter Gourd"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <img src="./Bitter.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
    </div>
    </div>
    </div>
    <Carousel/>

    {/* check */}

    <div className="container-fluid mb-5   ">
  <div className="row py-2">
  <img src="./Main11.jpg" alt="" />
</div>
      <div className="row">
             {/* <!-- First Video --> */}
        <div className="col-md-4">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/QUXWpHNXTKI" frameborder="0"></iframe> 
          </div>
        </div>
             {/* <!-- Second Video --> */}
        <div className="col-md-4">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/Hmmg3UuyV3M" frameborder="0"></iframe> 
            </div>
          </div>
             {/* <!-- Third Video --> */}
          <div className="col-md-4">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/biwH-WTHf24" frameborder="0"></iframe> 
            </div>
          </div>

        </div>
      </div>







    {/*  */}

<Footer/>

 </>
  );
}
