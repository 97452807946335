import React from 'react';
import { Link } from 'react-router-dom';


export default function 
() {
  return (
    <div>

<div id='footer'>
    <div className="container">
<div className="row">
    <div className="col-md-4 my-4">
    <h3 className='text-light '>About us </h3>
<div className="picv my-4"><img src="./main15.jpg" style={{height:"100%",width:"100%"}} alt="" /></div>
<p className='text-secondary my-3'>Our Products are freshly harvested, washed ready <br /> for Box and Finally <span style={{color:"yellowgreen"}}>[...]</span></p>

    </div>


    <div className="col-md-4 my-4">
    <h3 className='text-light text-center'>Products </h3>
    <ul className='my-3' style={{color:"greenyellow"}}>
  
    <Link to="/amm" style={{textDecoration:"none",listStyle:"none"}}>
   <li >
<p style={{color:"grey"}}>Mango</p>

   </li>
     </Link>
     <Link to="/orange" style={{textDecoration:"none",listStyle:"none"}}>
   <li >
<p style={{color:"grey"}}>Orange</p>

   </li>
   </Link>
   <Link to="/" style={{textDecoration:"none",listStyle:"none"}}>

   <li >
<p style={{color:"grey"}}>Jamun</p>

   </li>
   </Link>

   <Link to="/onions" style={{textDecoration:"none",listStyle:"none"}}>
   <li >
<p style={{color:"grey"}}>Onions</p>

   </li>
</Link>

<Link to="/potato" style={{textDecoration:"none",listStyle:"none"}}>

   <li >
<p style={{color:"grey"}}>Potatoes</p>

   </li>
</Link>
<Link to="/" style={{textDecoration:"none",listStyle:"none"}}>

   <li >
<p style={{color:"grey"}}>Bitter Gourd </p>

   </li>
</Link>
<Link to="/" style={{textDecoration:"none",listStyle:"none"}}>


   <li >
<p style={{color:"grey"}}> Peas </p>

   </li>
</Link>
<Link to="/" style={{textDecoration:"none",listStyle:"none"}}>

   <li >
<p style={{color:"grey"}}> All Fruits & vegetables </p>

   </li>
</Link>

   </ul> 
    </div>
    <div className="col-md-4 my-4">
    <h3 className='text-light '>Contact us </h3>

   
 <p className='text-secondary' ><span 
style={{color:"yellowgreen"}}>
   Email: </span>
   <a href='mailto:' style={{textDecoration:"none",listStyle:"none",color:'grey'}}>Aimsimpex96@gmail.com</a>
 </p>
 
 
 
 <p className='text-secondary'><span 
style={{color:"yellowgreen"}}>Instagram : </span>

<a href='https://www.instagram.com/aimsimpex96'style={{textDecoration:"none",listStyle:"none",color:'grey'}}>@aimsimpex96</a> </p>


 <p className='text-secondary'><span 
style={{color:"yellowgreen"}}>Number : </span>
<a href='callto:' style={{textDecoration:"none",listStyle:"none",color:'grey'}}>+92345-6708105</a>
 </p>

 <p className='text-secondary'><span 
style={{color:"yellowgreen"}}>WhatsApp : </span>
<a  href='https://wa.me/message/XZHU3LGEV6JXO1' style={{textDecoration:"none",listStyle:"none",color:'grey'}}> +92345-7253888</a>
</p>



 <p className='text-secondary'><span 
style={{color:"yellowgreen"}}> Address : </span>
 H37/2 Street 23 Shafiq-Abad, Bund Road Lahore,Punjab,Pakistan.</p>

 <p className='text-secondary'>
For Product Registration and general enquires please <span style={{color:"yellowgreen"}}>contact us .</span>

 </p>
    </div>


    
</div>
<hr style={{background:"white"}} />
<div className="row">
   <div className="col-md-8 mb-3 d-flex justify-content-space-around">
   <div className="gap ">

    <a href="https://www.facebook.com/AIMS-IMPEX-Pvt-Ltd-Import-Export-278369513955818/"><div className="facebook"></div></a> 
     <a href="https://www.instagram.com/aimsimpex96"><div className="instgram"></div></a>

   <a href="https://twitter.com/AimsImpex"><div className="Twitter"></div></a>
   <a href="https://www.linkedin.com/in/aims-impex"><div className="Link-din"></div></a> 
   <a href="https://www.pinterest.com/aimsimpex96"><div className="printest"></div></a>
   <a href="https://wa.me/message/XZHU3LGEV6JXO1"><div className="whatsapp"></div></a>  
   <a href="https://www.youtube.com/channel/UC17RFHUdUbSar64ne04bHwA"><div className="youtube"></div></a>  
   
    
     </div>
   </div>
</div>

    </div>
</div>


    </div>
  )
}



