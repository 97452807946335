import React from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
function Potato() {
  return (
    <div><div>    


    <Navbar/>
    <div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:""}}>Asterix Potato <span style={{color:"black"}}></span> </h2>
        
        <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"red", fontWeight:"bolder"}}>"Asterix Potato"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <img src="./AsterixPotato.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
    </div>
    </div>
    </div>
    
    
    <div className="container" style={{backgroundColor:"white"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:""}}>Lady-Rosetta Potato<span style={{color:"black"}}></span> </h2>
    
        <p className='my-5 text-center' >We deliver fresh farm <span style={{color:"goldenrod", fontWeight:"bolder"}}>"Lady-Rosetta Potato"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <img src="./Lady-RosettaPotato.png" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
    </div>
    </div>
    </div>
    
    
    
    <div className="container" style={{backgroundColor:"whitesmoke"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:""}}>Mozika Potato <span style={{color:"black"}}></span> </h2>
    
        <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"goldenrod", fontWeight:"bolder"}}>"Mozika Potato"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <img src="./mozikaPotato.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
    </div>
    </div>
    </div>
{/* add 4th  */}

 
<div className="container" style={{backgroundColor:"white"}} >
    <div className="row my-5">
    
    <div className="col-md-6">
        <h2 className='about-text1 text-center' style={{color:""}}>Santy Potato<span style={{color:"black"}}></span> </h2>
    
        <p className='my-5 text-center' >We deliver fresh farm <span style={{color:"goldenrod", fontWeight:"bolder"}}>"Santy Potato"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
    </div>
    
    <div className="col-md-4 offset-md-2 my-4">
    <div className="vege1" style={{height:"400px"}}>
    <img src="./SantyPotato.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
    </div>
    </div>
    </div>




{/* add 4th */}
    </div> 
    <Footer/></div>
  )
}

export default Potato