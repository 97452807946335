import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Onions() {
  return (
    <div>

<div>    


<Navbar/>
<div className="container" style={{backgroundColor:"whitesmoke"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>Red Onions <span style={{color:"black"}}></span> </h2>
    
    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"red", fontWeight:"bolder"}}>"Red Onions"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./RedOnion.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>


<div className="container" style={{backgroundColor:"white"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>White Onions <span style={{color:"black"}}></span> </h2>

    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"goldenrod", fontWeight:"bolder"}}>"White Onions"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./WhiteOnion.webp" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>



<div className="container" style={{backgroundColor:"whitesmoke"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>Brown Onions<span style={{color:"black"}}></span> </h2>

    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"Goldenrod", fontWeight:"bolder"}}>"Brown Onions"</span>  to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./YellowOnion.webp" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>
</div> 
<Footer/>
    </div>
  )
}

export default Onions