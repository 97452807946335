import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Orange() {
  return (
    <div>


<div>    


<Navbar/>
<div className="container" style={{backgroundColor:"whitesmoke"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>Valencia Orange<span style={{color:"black"}}></span> </h2>
    
    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"Orange", fontWeight:"bolder"}}>"Valencia orange"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./Valencia.jpg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>


<div className="container" style={{backgroundColor:"white"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>Mandrain <span style={{color:"black"}}></span> </h2>

    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"orange", fontWeight:"bolder"}}>"Mandrain"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./MandarinOrange.jpeg" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>



<div className="container" style={{backgroundColor:"whitesmoke"}} >
<div className="row my-5">

<div className="col-md-6">
    <h2 className='about-text1 text-center' style={{color:""}}>Mosambi <span style={{color:"black"}}></span> </h2>

    <p className='my-5 text-center' >We deliver fresh farm  <span style={{color:"orange", fontWeight:"bolder"}}>"Mosabi"</span> to our esteemed customers. Our team members are farm and horticulture experts, who helps us to choose the best quality products from the farmer’s house across the country.</p>
</div>

<div className="col-md-4 offset-md-2 my-4">
<div className="vege1" style={{height:"400px"}}>
<img src="./MusambiOrange.webp" style={{width:"100%", height:"100%",boxShadow:"0px 0px 20px 5px black"}} alt="" /></div>
</div>
</div>
</div>
</div> 
<Footer/>
    </div>
  )
}

export default Orange